<!--
 * @Author: Akko
 * @Description: 家庭成员信息
-->
<template>
    <div>
        <el-form ref="userForm" v-loading="loading" :model="userForm" label-width="120px" class="demo-ruleForm">

            <!-- 配偶模块  -->
            <div v-if="marriedStatus==='1'||marriedStatus==='4'" class="card-container">
                <div class="card-heard">主申请人配偶</div>
                <div v-for="(item,idx) in userForm.subsetList" :key="item.id">
                    <el-row v-if="item.relation==='3'" class="block-top">
                        <el-col :span="12">
                            <!-- 左边输入框的内容 -->
                            <!-- 姓名 -->
                            <div class="one-input">
                                <el-form-item label="姓名:" :prop="'subsetList.'+idx+'.subsetName'" :rules="[{required: true,message: '请输入姓名',trigger: 'blur'}]">
                                    <el-input v-model.trim="item.subsetName" class="full-width" placeholder="请输入" maxlength="20" clearable />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="曾用名:" prop="usedName">
                                    <el-input v-model.trim="item.usedName" class="full-width" placeholder="请输入" maxlength="20" clearable />
                                </el-form-item>
                            </div>

                            <div class="one-input">
                                <el-form-item label="出生日期:" :prop="'subsetList.'+idx+'.birthday'" :rules="[{required: true,message: '请选择出生日期',trigger: 'change'}]">
                                    <el-date-picker v-model="item.birthday" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM-dd" class="full-width" type="date" placeholder="选择出生日期" />
                                </el-form-item>
                            </div>

                            <div class="one-input">
                                <el-form-item label="出生城市:" :prop="'subsetList.'+idx+'.birthPlaceAboard'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="item.birthPlaceAboard" style="margin-top: 9px;" @change="changeBirthPalce($event,item)">
                                            <el-radio :label="0">国内</el-radio>
                                            <el-radio :label="1">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="item.birthPlaceAboard===0" :key="3" class="inline-item" label="" :prop="'subsetList.'+idx+'.birthPlace.area'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]" style="margin-bottom: 0px">
                                            <CitySelect v-model="item.birthPlace.area" class="city-inline" :placeholder="holderText" />
                                        </el-form-item>
                                        <el-form-item v-if="item.birthPlaceAboard===1" :key="4" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.birthPlace.foreign'" :rules="[{required: true,message: '请输入出生城市',trigger: 'blur'}]" style="margin-bottom: 0px">
                                            <el-input v-model.trim="item.birthPlace.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="目前居住国家:" :prop="'subsetList.'+idx+'.liveCountry'" :rules="[{required: true,message: '请选择目前居住国家',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="item.liveCountry" style="margin-top: 9px;" @change="changeLiveCountry($event,item)">
                                            <el-radio label="中国">中国</el-radio>
                                            <el-radio label="国外">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="item.liveCountry==='国外'" label="" class="city-inline-add" :prop="'subsetList.'+idx+'.liveCountryTxt'" :rules="[{required: true,message: '请填写国家名称',trigger: 'blur'}]" style="margin-bottom: 0px">
                                            <el-input v-model.trim="item.liveCountryTxt" placeholder="请填写国家名称" maxlength="100" clearable />
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <!-- 右边输入框的内容 -->
                            <!-- 职业 -->
                            <div class="one-input">
                                <el-tooltip content="如无业，请选择其他并填写【无】" placement="top">
                                    <img class="sigh-img" style="left: 33px;top:7px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="职业:" :prop="'subsetList.'+idx+'.occupation'" :rules="[{required: true,message: '请选择职业',trigger: 'change'}]">
                                    <el-select v-model="item.occupation" class="input-width" placeholder="请选择" :popper-append-to-body="false" clearable @change="item.occupationName=''">
                                        <el-option v-for="occupitem in workOption" :key="occupitem.id" :label="occupitem" :value="occupitem" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="item.occupation==='在职'" class="one-input">
                                <el-form-item label="" :prop="'subsetList.'+idx+'.occupationName'" :rules="[{required: true,message: '请输入职位名称',trigger: 'blur'}]">
                                    <el-input v-model.trim="item.occupationName" class="input-width" placeholder="请输入职位名称" maxlength="20" clearable />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="香港身份证号码:" prop="hkIdentityCard">
                                    <div class="input-width">
                                        <el-input v-model.trim="item.hkIdentityCard" placeholder="若有请填写" maxlength="8" clearable onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" />
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="是否随行:" :prop="'subsetList.'+idx+'.follow'" :rules="[{required: true,message: '请选择是否随行',trigger: 'change'}]">
                                    <el-radio-group v-model="item.follow">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="2">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-tooltip placement="top">
                                    <div slot="content">仅有毕业证无效<br />必须有学位证</div>
                                    <img class="sigh-img" style="left: 21px;bottom: 32px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="学位证:" :prop="'subsetList.'+idx+'.hasDegree'" :rules="[{required: true,message: '请选择学位证',trigger: 'change'}]">
                                    <el-select v-model="item.hasDegree" class="input-width" placeholder="请选择" :popper-append-to-body="false" clearable>
                                        <el-option v-for="hasitem in degreeList" :key="hasitem.id" :label="hasitem.name" :value="hasitem.idx" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="目前居住地址:" :prop="'subsetList.'+idx+'.nows'" :rules="[{required: true,message: '请选择目前居住地址',trigger: 'change'}]">
                                    <el-radio-group v-model="item.nows" @change="changeNows($event,item)">
                                        <el-radio :label="1">与主申请人相同</el-radio>
                                        <el-radio :label="0">与主申请人不相同</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <!-- 目前居住地址与主申请人不同 -->
                            <div class="one-input">
                                <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.addressAboard'" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="item.addressAboard" style="margin-top: 9px;" @change="changeAddress($event,item)">
                                            <el-radio :label="0">国内</el-radio>
                                            <el-radio :label="1">国外</el-radio>
                                        </el-radio-group>
                                        <!-- 国内不同住址 -->
                                        <el-form-item v-if="item.addressAboard===0" class="inline-item" label="" :prop="'subsetList.'+idx+'.address.area'" :rules="[{required: true,message: '请选择地址',trigger: 'change'}]" style="margin-bottom: 0px">
                                            <CitySelect v-model="item.address.area" class="city-inline" :placeholder="holderText" />
                                        </el-form-item>
                                        <!-- 国外不同住址 -->
                                        <el-form-item v-if="item.addressAboard===1" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.address.foreign'" :rules="[{required: true,message: '请输入地址',trigger: 'blur'}]" style="margin-bottom: 0px">
                                            <el-input v-model.trim="item.address.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>
                            <!-- 详细地址 -->
                            <div class="one-input">
                                <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.address.details'" :rules="[{required: true,message: '请输入详细地址',trigger: 'blur'}]">
                                    <el-input v-model.trim="item.address.details" type="textarea" class="detailed-address" maxlength="100" :rows="2" placeholder="请输入详细地址" clearable />
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 子女模块 -->
            <div class="card-container">
                <!-- <div v-if="marriedStatus!='2'" class="card-container"> -->
                <div class="card-heard">子女</div>
                <div v-for="(item,idx) in userForm.subsetList" :key="item.id">
                    <div v-if="item.relation==='4'" class="block-box sis-box">
                        <div class="block-heard">
                            <p />
                            <div class="del-cur" @click="removeMember(item,idx)">删除</div>
                        </div>
                        <el-row class="block-top">
                            <el-col :span="12">
                                <!-- 左边输入框的内容 -->
                                <!-- 姓名 -->
                                <div class="one-input">
                                    <el-form-item label="姓名:" :prop="'subsetList.'+idx+'.subsetName'" :rules="[{required: true,message: '请输入姓名',trigger: 'blur'}]">
                                        <el-input v-model.trim="item.subsetName" class="full-width" placeholder="请输入" maxlength="20" clearable />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="曾用名:" prop="usedName">
                                        <el-input v-model.trim="item.usedName" class="full-width" placeholder="请输入" maxlength="20" clearable />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="性别:" :prop="'subsetList.'+idx+'.sex'" :rules="[{required: true,message: '请选择性别',trigger: 'change'}]">
                                        <el-select v-model="item.sex" class="full-width" placeholder="请选择" clearable>
                                            <el-option label="男" :value="1" />
                                            <el-option label="女" :value="2" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="出生日期:" :prop="'subsetList.'+idx+'.birthday'" :rules="[{required: true,message: '请选择出生日期',trigger: 'change'}]">
                                        <el-date-picker v-model="item.birthday" :picker-options="pickerBeginDateBefore" class="full-width" value-format="yyyy-MM-dd" type="date" placeholder="选择出生日期" />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="出生城市:" :prop="'subsetList.'+idx+'.birthPlaceAboard'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]">
                                        <div class="flex-center">
                                            <el-radio-group v-model="item.birthPlaceAboard" style="margin-top: 9px;" @change="changeBirthPalce($event,item)">
                                                <el-radio :label="0">国内</el-radio>
                                                <el-radio :label="1">国外</el-radio>
                                            </el-radio-group>
                                            <el-form-item v-if="item.birthPlaceAboard===0" :key="5" class="inline-item" label="" :prop="'subsetList.'+idx+'.birthPlace.area'" :rules="[{required: true,message: '请选择出生城市',trigger: 'change'}]">
                                                <CitySelect v-model="item.birthPlace.area" class="city-inline" :placeholder="holderText" />
                                            </el-form-item>
                                            <el-form-item v-if="item.birthPlaceAboard===1" :key="6" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.birthPlace.foreign'" :rules="[{required: true,message: '请输入出生城市',trigger: 'blur'}]" style="margin-bottom: 0px">
                                                <el-input v-model.trim="item.birthPlace.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <!-- 右边输入框的内容 -->
                                <div class="one-input">
                                    <el-tooltip placement="top">
                                        <div slot="content">1、如已上学，请填写【学生】<br /> 2、 如未上学，请填写【无】</div>
                                        <img class="sigh-img" style="left: 33px;bottom: 32px;" src="@/assets/img/hover.png" alt="">
                                    </el-tooltip>
                                    <el-form-item label="职业:" :prop="'subsetList.'+idx+'.occupation'" :rules="[{required: true,message: '请选择职业',trigger: 'change'}]">
                                        <el-select v-model="item.occupation" class="input-width" placeholder="请选择" :popper-append-to-body="false" clearable @change="item.occupationName=''">
                                            <el-option v-for="puitem in studentsList" :key="puitem.id" :label="puitem" :value="puitem" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div v-if="item.occupation==='在职'" class="one-input">
                                    <el-form-item label="" :prop="'subsetList.'+idx+'.occupationName'" :rules="[{required: true,message: '请输入职位名称',trigger: 'blur'}]">
                                        <el-input v-model.trim="item.occupationName" class="input-width" placeholder="请输入职位名称" maxlength="20" clearable />
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="香港身份证号码:" prop="hkIdentityCard">
                                        <div class="input-width">
                                            <el-input v-model.trim="item.hkIdentityCard" placeholder="若有请填写" maxlength="8" clearable onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" />
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-tooltip placement="top">
                                        <div slot="content">1、18周岁以上子女，不能随行<br /> 2、如对子女无抚养权，不能随行</div>
                                        <img class="sigh-img" style="left: 8px;bottom: 32px;" src="@/assets/img/hover.png" alt="">
                                    </el-tooltip>
                                    <el-form-item label="是否随行:" :prop="'subsetList.'+idx+'.follow'" :rules="[{required: true,message: '请选择是否随行',trigger: 'change'}]">
                                        <el-radio-group v-model="item.follow">
                                            <el-radio :label="1">是</el-radio>
                                            <el-radio :label="2">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="目前居住国家:" :prop="'subsetList.'+idx+'.liveCountry'" :rules="[{required: true,message: '请选择目前居住国家',trigger: 'change'}]">
                                        <div class="flex-center">
                                            <el-radio-group v-model="item.liveCountry" style="margin-top: 9px;" @change="changeLiveCountry($event,item)">
                                                <el-radio label="中国">中国</el-radio>
                                                <el-radio label="国外">国外</el-radio>
                                            </el-radio-group>
                                            <el-form-item v-if="item.liveCountry==='国外'" label="" class="city-inline-add" :prop="'subsetList.'+idx+'.liveCountryTxt'" :rules="[{required: true,message: '请填写国家名称',trigger: 'blur'}]" style="margin-bottom: 0px">
                                                <el-input v-model.trim="item.liveCountryTxt" placeholder="请填写国家名称" maxlength="100" clearable />
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="one-input">
                                    <el-form-item label="目前居住地址:" :prop="'subsetList.'+idx+'.nows'" :rules="[{required: true,message: '请选择目前居住地址',trigger: 'change'}]">
                                        <el-radio-group v-model="item.nows" @change="changeNows($event,item)">
                                            <el-radio :label="1">与主申请人相同</el-radio>
                                            <el-radio :label="0">与主申请人不相同</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <!-- 目前居住地址与主申请人不同 -->
                                <div class="one-input">
                                    <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.addressAboard'" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                                        <div class="flex-center">
                                            <el-radio-group v-model="item.addressAboard" style="margin-top: 9px;" @change="changeAddress($event,item)">
                                                <el-radio :label="0">国内</el-radio>
                                                <el-radio :label="1">国外</el-radio>
                                            </el-radio-group>
                                            <!-- 国内不同住址 -->
                                            <el-form-item v-if="item.addressAboard===0" class="inline-item" label="" :prop="'subsetList.'+idx+'.address.area'" :rules="[{required: true,message: '请选择地址',trigger: 'change'}]" style="margin-bottom: 0px">
                                                <CitySelect v-model="item.address.area" class="city-inline" :placeholder="holderText" />
                                            </el-form-item>
                                            <!-- 国外不同住址 -->
                                            <el-form-item v-if="item.addressAboard===1" label="" class="inline-item city-input-width city-inline" :prop="'subsetList.'+idx+'.address.foreign'" :rules="[{required: true,message: '请输入地址',trigger: 'blur'}]" style="margin-bottom: 0px">
                                                <el-input v-model.trim="item.address.foreign" placeholder="请输入城市" maxlength="100" clearable />
                                            </el-form-item>
                                        </div>
                                    </el-form-item>
                                </div>
                                <!-- 详细地址 -->
                                <div class="one-input">
                                    <el-form-item v-if="item.nows===0" label="" :prop="'subsetList.'+idx+'.address.details'" :rules="[{required: true,message: '请输入详细地址',trigger: 'blur'}]">
                                        <el-input v-model.trim="item.address.details" type="textarea" class="detailed-address" :rows="2" placeholder="请输入详细地址" clearable />
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="add-people" @click="addMember">+新增成员</div>
            </div>

            <!-- 家庭背景 -->
            <div class="card-container">
                <div class="card-heard">家庭背景</div>
                <div class="basics-box">
                    <div class="basics-title">1、至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民</div>
                    <el-form-item label-width="0" prop="question.backgroundMember.has" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-radio-group v-model="userForm.question.backgroundMember.has">
                            <el-radio label="Y">满足</el-radio>
                            <el-radio label="N">不满足</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </div>

            <!-- 居住信息 -->
            <div class="card-container">
                <div class="card-heard">居住信息</div>
                <div v-for="(domain, idx) in userForm.residenceList" :key="domain.id" class="block-box">
                    <div class="block-heard1">
                        <p>居住信息{{ idx+1 }}</p>
                        <div v-if="idx!=0" class="del-cur" @click.prevent="removeMemner(domain)">删除</div>
                    </div>
                    <div class="live-box">
                        <p>自出生后曾居住12个月或更长时间的所有国家，【以护照上的出入境章为准】，如从未出国一年以上，则为“出生年月-至今”</p>
                        <div class="one-input">
                            <el-form-item label="国家:" :prop="'residenceList.'+idx+'.country'" :rules="[{required: true,message: '请输入国家名称',trigger: 'blur'}]">
                                <el-input v-model.trim="domain.country" class="full-width" placeholder="请输入国家名称" maxlength="100" clearable />
                            </el-form-item>
                        </div>
                        <div class="one-input">
                            <el-form-item label="开始时间:" :prop="'residenceList.'+idx+'.startTime'" :rules="[{required: true,message: '请选择开始时间',trigger: 'change'}]">
                                <el-date-picker v-model="domain.startTime" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" class="full-width" type="month" placeholder="请选择" @change="(value) => pikerStartTime(value, idx)" />
                            </el-form-item>
                            <el-form-item label="结束时间:" :prop="'residenceList.'+idx+'.endTime'" :rules="[{required: true,message: '请选择结束时间',trigger: 'change'}]">
                                <el-date-picker v-if="!domain.endTimeChecked" v-model="domain.endTime" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" class="end-width" type="month" placeholder="请选择" @change="(value) => pikerEndTime(value, idx)" />
                                <el-checkbox v-model="domain.endTimeChecked" @change="endTimeNow($event,domain)">至今</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="add-people" @click="addLive">+新增居住信息</div>
            </div>

            <!-- 保存按钮 -->
            <div class="operations-group">
                <el-button class="person-btn" @click="saveForm('last')">上一步</el-button>
                <el-button v-if="(user.baseSchedule - 1) < 2" class="person-btn btn-blue" :loading="isSave" @click="saveForm('save')">保存</el-button>
                <el-button class="person-btn btn-blue" :loading="isNext" @click="saveForm('next')">下一步</el-button>
            </div>
        </el-form>
    </div>
</template>

<script lang="javascript">
import CitySelect from "@/components/CitySelect/CitySelect";
import { saveSubset, saveResidence, saveQuestion } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
export default {
    name: "Parent",
    components: {
        CitySelect,
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            checked: true,
            userForm: {
                question: {
                    backgroundMember: {
                        has: "",
                    },
                },
                residenceList: [
                    {
                        orderId: this.$route.params.id,
                        country: "",
                        startTime: "",
                        endTime: "",
                    },
                ], //居住信息
                option: "",
                subsetList: [
                    {
                        id: 0, //当前人物id
                        orderId: 0, //订单id
                        subsetName: "", //成员名称
                        usedName: "", //曾用名
                        sex: 1, //性别:0为未知,1为男,2为女
                        relation: "1", //关系:1：主申父亲,2：主申母亲,3:配偶 ,4：子女,5：兄弟姐妹
                        hasDegree: "", //是否有学位 0-无学位 1-学士 2-硕士 3-博士
                        nows: 0, //现居住地址 0-不一样 1-一样
                        occupation: "", //职业
                        hkIdentityCard: "", //香港身份证
                        birthday: "", //生日
                        follow: 0, //是否随同 0：待定 1：跟随 2：不跟随
                        addressAboard: 0, // 现居住地址 0-国内 1-国外
                        liveCountry: "", // 现定居国家 传字符串 目前居住国家 - 国外
                        liveCountryTxt: "", // 为国外时 需要传的参数 最后要删掉
                        occupationName: "", //在职的职业字段
                        address: {
                            //目前居住地址
                            area: [], //中国的话选城市
                            foreign: "", //外国的话输入城市
                            details: "", //目前居住地址与主申请人不同的详细地址
                            country: "", //目前居住国家国外的话 输入框国外的国家
                        },
                        birthPlace: {
                            //出生地址
                            area: [],
                            foreign: "",
                        },
                        birthPlaceAboard: 1, //出生城市 0-国内 1-国外
                        deceased: 0, //是否已故 0-否 1-是
                    },
                ],
                order: {}
            },
            degreeList: [
                { name: "无学位", idx: 0 },
                { name: "无学位（专科）", idx: 6 },
                { name: "无学位（本科）", idx: 5 },
                { name: "无学位（硕士）", idx: 4 },
                { name: "学士学位", idx: 1 },
                { name: "硕士学位", idx: 2 },
                { name: "博士学位", idx: 3 },
            ],
            studentsList: ["在职", "未上学", "学生", "无业"],
            workOption: ["在职", "退休", "无"],
            holderText: "请选择城市",
            marriedStatus: "1", // 婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
            haveSpouse: false, //是否有配偶
            loading: false,
            isSave: false, //防抖loading
            isNext: false,
            infomation: {}, //主申请人信息
        };
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 数据回显
        dataEcho() {
            this.loading = true;
            this.getInfoData(this.$route.params.id).then((res) => {
                if (res.code === 200) {
                    this.userForm.order = res.data.order;
                    this.userForm.subsetList = res.data.subsetList;
                    //家庭背景
                    if (res.data.question == null) {
                        this.userForm.question = {
                            backgroundMember: {
                                has: "",
                            },
                        };
                    } else {
                        this.userForm.question.id = res.data.question.id;
                        this.userForm.question.backgroundMember =
                            res.data.question.backgroundMember;
                    }
                    //居住信息
                    if (res.data.residenceList.length === 0) {
                        this.userForm.residenceList = [
                            {
                                orderId: this.$route.params.id,
                                country: "",
                                startTime: res.data.information.birthday.slice(0, 7),
                                endTime: "",
                            },
                        ];
                    } else {
                        res.data.residenceList.forEach((ele) => {
                            // 判断是否至今
                            if (ele.endTime === "至今") {
                                ele.endTimeChecked = true;
                                // ele.endTime = "2021-04-02";
                                ele.endTime = this.$utils.today();
                            } else {
                                ele.endTimeChecked = false;
                            }
                        });
                        this.userForm.residenceList = res.data.residenceList;
                    }

                    this.marriedStatus = res.data.information.married;
                    this.infomation = res.data.information;
                    res.data.subsetList.forEach((ele) => {
                        // 如果有配偶 不添加
                        if (ele.relation === "3") {
                            this.haveSpouse = true;
                        }

                        // 性别 int类型 0=>''
                        if (ele.sex === 0) {
                            ele.sex = "";
                        }

                        // 如果不是中国就要 新参数占位
                        if (ele.liveCountry === "中国") {
                            this.$set(ele, "liveCountryTxt", "");
                        } else {
                            this.$set(ele, "liveCountryTxt", ele.liveCountry);
                            ele.liveCountry = "国外";
                        }
                    });

                    // 申请人婚姻状况是 已婚和分居情况下 新增一个配偶模块
                    if (!this.haveSpouse) {
                        if (
                            this.marriedStatus === "1" ||
                            this.marriedStatus === "4"
                        ) {
                            this.userForm.subsetList.push({
                                orderId: this.$route.params.id,
                                relation: "3",
                                subsetName: "",
                                usedName: "",
                                birthday: "",
                                birthPlaceAboard: 0,
                                birthPlace: {
                                    area: [],
                                    foreign: "",
                                },
                                occupation: "",
                                occupationName: "",
                                liveCountry: "",
                                liveCountryTxt: "",
                                hkIdentityCard: "",
                                follow: "",
                                addressAboard: "",
                                hasDegree: "",
                                passNumber: "", //港澳通行证
                                identityCard: "", //中国身份证号码
                                nows: "",
                                address: {
                                    area: [],
                                    foreign: "",
                                    details: "",
                                },
                            });
                        }
                    }
                    this.loading = false;
                }
            });
        },
        // 至今选择
        endTimeNow(e, item) {
            if (e) {
                // item.endTime = "2021-04-02"; //占位
                item.endTime = this.$utils.today();
            } else {
                item.endTime = "";
            }
        },
        // 禁止开始时间乱选
        pikerStartTime(e, idx) {
            let startDate = e === null ? "" : e;
            let endDate = this.userForm.residenceList[idx].endTime;
            if (endDate !== "" && endDate !== null) {
                let result =
                    new Date(endDate).getTime() > new Date(startDate).getTime();
                if (!result) {
                    this.userForm.residenceList[idx].startTime = "";
                    this.$message.error("开始时间不得在结束时间之后");
                }
            }
        },
        // 禁止结束时间乱选
        pikerEndTime(e, idx) {
            let startDate = this.userForm.residenceList[idx].startTime;
            let endDate = e === null ? "" : e;
            if (startDate !== "" && startDate !== null) {
                let result =
                    new Date(startDate).getTime() < new Date(endDate).getTime();
                if (!result) {
                    this.userForm.residenceList[idx].endTime = "";
                    this.$message.error("结束时间不得在开始时间之前");
                }
            }
        },
        // 增加子女
        addMember() {
            this.userForm.subsetList.push({
                orderId: this.$route.params.id,
                relation: "4",
                subsetName: "",
                usedName: "",
                sex: "",
                birthday: "",
                birthPlaceAboard: 0,
                birthPlace: {
                    area: [],
                    foreign: "",
                },
                address: {
                    area: [],
                    foreign: "",
                    details: "",
                },
                occupation: "",
                hkIdentityCard: "",
                wofollowrk: "",
                addressAboard: "",
                occupationName: "",
                nows: "",
                liveCountry: "",
                liveCountryTxt: "",
                key: Date.now(),
            });
        },
        // 移除子女
        removeMember(item) {
            this.$confirm("确定要删除该条成员信息吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let index = this.userForm.subsetList.indexOf(item);
                    if (index !== -1) {
                        this.userForm.subsetList.splice(index, 1);
                    }
                })
                .catch(() => {});
        },
        // 添加居住信息
        addLive() {
            this.userForm.residenceList.push({
                orderId: this.$route.params.id,
                country: "",
                startTime: "",
                endTime: "",
                key: Date.now(),
            });
        },
        // 删除居住信息
        removeMemner(item) {
            this.$confirm("确定要删除该条居住信息吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let index = this.userForm.residenceList.indexOf(item);
                    if (index !== -1) {
                        this.userForm.residenceList.splice(index, 1);
                    }
                })
                .catch(() => {});
        },
        // 保存表单
        saveForm(txt) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            // 处理是否至今
            this.userForm.residenceList.forEach((ele) => {
                if (
                    ele.endTimeChecked != undefined &&
                    ele.endTimeChecked != false
                ) {
                    ele.endTime = "至今";
                }
            });
            if (txt === "save") {
                this.isSave = true;
                this.userForm.option = "save";
                // 如果不是中国 替換参数
                this.userForm.subsetList.forEach((ele) => {
                    if (ele.liveCountry !== "中国") {
                        ele.liveCountry = ele.liveCountryTxt;
                    }
                    delete ele.liveCountryTxt;
                });
                return this.hanledSaveQuestion(2);
            } else if (txt === "last") {
                this.$router.replace({
                    name: "ptep1",
                });
            } else {
                this.userForm.option = "submit";
                this.$refs.userForm.validate((valid) => {
                    if (valid) {
                        let noPass = false; //不通過
                        for (let val of this.userForm.subsetList) {
                            // 校验出生城市国内
                            if (
                                val.birthPlaceAboard === 0 &&
                                val.birthPlace.area[0] === ""
                            ) {
                                this.$message.error("请完善出生城市");
                                noPass = true;
                                return false;
                            }
                            // 校验居住地址国内 (除父母)
                            if (val.relation === "3" || val.relation === "4") {
                                if (val.nows === 0) {
                                    if (
                                        val.addressAboard === 0 &&
                                        val.address.area[0] === ""
                                    ) {
                                        this.$message.error(
                                            "请完善目前居住地址"
                                        );
                                        noPass = true;
                                        return false;
                                    }
                                } else if (val.nows === 1) {
                                    // 判断居住地址和主申请人相同
                                    val.address = this.infomation.address;
                                }
                            }
                            // 校验身份证
                            if (val.relation === "3") {
                                if (val.identityCard) {
                                    if (
                                        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
                                            val.identityCard
                                        )
                                    ) {
                                        this.$message.error(
                                            "请输入正确的身份证号码"
                                        );
                                        return false;
                                    }
                                }
                            }
                        }
                        if (!noPass) {
                            // 如果不是中国 替換参数
                            this.userForm.subsetList.forEach((ele) => {
                                if (ele.liveCountry !== "中国") {
                                    ele.liveCountry = ele.liveCountryTxt;
                                }
                                delete ele.liveCountryTxt;
                            });
                            return this.hanledSaveQuestion(3);
                        }
                    } else {
                        return this.$message.error("请完善信息");
                    }
                });
            }
        },
        hanledSaveQuestion(parmas) {
            this.userForm.option === "submit"
                ? (this.isNext = true)
                : (this.isSave = false);
            this.userForm.question.orderId = this.$route.params.id;
            // this.userForm.order = { id: this.$route.params.id };
            return new Promise((resolve,reject) => {
                return Promise.all([saveSubset(this.userForm),saveResidence(this.userForm),saveQuestion(this.userForm)]).then((res) => {
                    if(res.every(v => v.code === 200)) {
                        // 切换步骤保存 下一步的时候才设置
                        if (this.userForm.option === "submit") {
                            this.$message.success("提交成功！");
                            this.$router.replace({
                                name: "ptep" + parmas,
                            });
                        } else {
                            this.$message.success("保存成功！");
                            this.dataEcho();
                        }
                        resolve(res[2]);
                    } else {
                        this.isNext = false;
                        this.isSave = false;
                        reject(res[2])
                    }
                })
            })
        },
        // // 发起保存动作
        // hanledSaveQuestion(parmas) {
        //     this.userForm.option === "submit"
        //         ? (this.isNext = true)
        //         : (this.isSave = false);
        //     this.userForm.order = { id: this.$route.params.id };
        //     saveSubset(this.userForm).then((res) => {
        //         if (res.code === 200) {
        //             this.saveResiFun(parmas);
        //         } else {
        //             this.isNext = false;
        //             this.isSave = false;
        //         }
        //     });
        // },
        // // 保存居住信息
        // saveResiFun(parmas) {
        //     saveResidence(this.userForm).then((res) => {
        //         if (res.code === 200) {
        //             this.saveQuesFun(parmas);
        //         } else {
        //             this.isNext = false;
        //             this.isSave = false;
        //         }
        //     });
        // },
        // // 保存问题
        // saveQuesFun(parmas) {
        //     this.userForm.question.orderId = this.$route.params.id;
        //     saveQuestion(this.userForm).then((res) => {
        //         if (res.code === 200) {
        //             // 切换步骤保存 下一步的时候才设置
        //             if (this.userForm.option === "submit") {
        //                 this.$message.success("提交成功！");
        //                 this.$router.replace({
        //                     name: "ptep" + parmas,
        //                 });
        //             } else {
        //                 this.$message.success("保存成功！");
        //                 this.dataEcho();
        //             }
        //         }
        //         this.isNext = false;
        //         this.isSave = false;
        //     });
        // },
        // 更改出生城市
        changeBirthPalce(e, item) {
            if (e === 0) {
                item.birthPlace.foreign = "";
            } else {
                item.birthPlace.area = [];
            }
        },
        // 更改居住国家
        changeLiveCountry(e, item) {
            if (e === "中国") {
                item.addressAboard = 0;
                item.liveCountryTxt = "";
            } else {
                item.addressAboard = 1;
            }
        },
        // 更改居住地址-是否相同
        changeNows(e, item) {
            if (e === 1) {
                item.addressAboard = "";
                item.address.foreign = "";
                item.address.details = "";
                item.address.area = [];
            }
        },
        // 更改居住地址国内国外
        changeAddress(e, item) {
            item.address.details = "";
            if (e === 0) {
                item.address.foreign = "";
            } else {
                item.address.area = [];
            }
        },
    },
};
</script>

<style lang="less" scoped>
.one-input {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.sigh-img {
    position: absolute;
}

.input-width {
    width: 338px;
}

.name-width {
    width: 282px;
}

.full-width {
    width: 345px;
}

.check-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.see-right {
    display: block;
}

.el-radio {
    margin-right: 21px;
}

.sis-box {
    border-bottom: 1px solid #dcdfe6;
}

.city-input-width {
    width: 200px;
}

.in-city {
    display: block;
}
.block-heard1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #dcdfe6;
    padding: 0 16px;
    height: 36px;
}
.live-box {
    padding: 16px 16px 0 16px;

    > p {
        font-size: 14px;
        color: #606266;
        margin-bottom: 24px;
    }
    .el-checkbox {
        margin-left: 20px;
    }
}
.parent-line {
    &::before {
        content: "";
        width: 928px;
        height: 1px;
        background: #dcdfe6;
        position: absolute;
        bottom: 0;
        left: 16px;
    }
}

.inline-item {
    display: inline-block;
}

.flex-center {
    display: flex;
    align-items: flex-start;
}

.city-inline {
    display: inline-block;
}

.el-form-item--small.el-form-item {
    margin-bottom: 24px;
}

.sister-box {
    border-bottom: 1px solid #dcdfe6;

    .sister-heard {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 16px;
        color: #1890ff;
        height: 36px;
    }
}

.add-people {
    width: 928px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #dcdfe6;
    color: #409eff;
    font-size: 14px;
    text-align: center;
    margin: 16px auto;
    cursor: pointer;
}

.basics-box {
    padding: 0 24px;
    color: #606266;
    font-size: 14px;
    margin-top: 25px;

    .basics-title {
        margin-bottom: 9px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 3px;
            color: red;
        }
    }

    .basics-title.no-tip {
        padding-left: 0;

        &::before {
            content: "";
        }
    }
}

.block-heard {
    border: none;
}

.del-cur {
    cursor: pointer;
}

.detailed-address {
    width: 338px;
}

/deep/.el-scrollbar .el-select-dropdown__wrap {
    margin-bottom: -16px !important;
}

/deep/.el-select-dropdown__wrap {
    border: none !important;
}

/deep/.el-scrollbar .el-cascader-menu__wrap {
    margin-bottom: -16px !important;
}

/deep/.el-scrollbar .el-cascader-menu__wrap {
    margin-bottom: -16px !important;
}
</style>
